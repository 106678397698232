<template>
<div id="wrapper">
        <div id="header">
            <div id="logo">
                <img :src="`${publicPath}logo.png`" />
            </div>
            <current-user />
        </div>
        <sidebar />
        <div>
        <div class="mb-5">
            <show-general-errors />
            <h3>Note Component Demo</h3>
            <b-tabs v-model="tabIndex">
                <b-tab title="List" active></b-tab>
                <b-tab title="Inline"></b-tab>
            </b-tabs>
        </div>
            <!-- Examples of NoteList component with mode='list' and mode='inline' -->
            <notes-list v-if="tabIndex === 0" claimId="400" perPage="6" preauthId="1" mode="list"/>
            <div v-if="tabIndex === 1" class="w-50 ml-auto">
                <notes-list claimId="400" preauthId="1" mode="inline"/>
            </div>
        </div>
        <div id="footer"></div>
</div>
</template>

<script>
import Sidebar from "../components/Sidebar.vue"
import CurrentUser from "../components/CurrentUser.vue"
import NotesList from '../components/notes/NotesList.vue'
import ShowGeneralErrors from "../components/ShowGeneralErrors.vue"

export default {
    name: 'Notes',
    components: {
        Sidebar,
        CurrentUser,
        NotesList,
        ShowGeneralErrors
    },
    data(){
        return {
            publicPath: process.env.BASE_URL,
            tabIndex: 0
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1 {
    font-family: sans-serif;
    display: inline;
}
</style> 